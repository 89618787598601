<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import classnames from 'classnames'
</script>

<template>
  <Menu as="div" :class="getMenuContainerClasses()" id="clientList">
    <div class="tw-w-full">
      <MenuButton
        :class="getMenuButtonClasses()"
        :disabled="loading"
        data-testid="client-list-menu-button"
      >
        {{ loading ? 'Loading...' : selected?.partnerName }}
        <ChevronDownIcon v-if="!loading" class="tw-mr-1 tw-h-5 tw-w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <MenuItems :class="getMenuItemClasses()">
        <div class="tw-py-1" v-for="option in clientOptions" :key="option">
          <MenuItem @click="selectOption(option)">
            <a
              href="#"
              class="hover:tw-bg-gray-100 tw-text-gray-900 tw-font-noto tw-font-light tw-text-base tw-block tw-px-4 tw-py-2"
              >{{ option.partnerName }}</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
export default {
  name: 'ClientList',
  props: {
    clientOptions: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('optionSelected', option)
    },
    getMenuContainerClasses() {
      return classnames('tw-relative tw-z-20 tw-inline-block tw-text-left tw-w-full', {
        'tw-ml-6': !this.isMobile
      })
    },
    getMenuButtonClasses() {
      return classnames(
        'tw-inline-flex tw-w-[265px] tw-h-[44px] tw-font-light tw-font-noto tw-text-white tw-bg-white tw-bg-opacity-20 tw-relative tw-items-center tw-justify-between tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-text-gray-900 tw-shadow-sm',
        {
          'tw-w-full': this.isMobile
        }
      )
    },
    getMenuItemClasses() {
      return classnames(
        'tw-absolute tw-w-[265px] tw-z-10 tw-mt-2 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg focus:tw-outline-none',
        {
          'tw-w-full': this.isMobile
        }
      )
    }
  }
}
</script>
